<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                @if (brand === 'tuitionrefundplan') {
                    <div class="nav">                        
                        <span class="nav-item">
                            <a class="nav-link" href="{{ 'dewar-legal.privacy' | translate }}" target="_blank">{{ 'dewar-legal.privacy-text' | translate }}</a>
                        </span>
                        <span class="nav-item">
                            <a class="nav-link" href="{{ 'dewar-legal.terms' | translate }}" target="_blank">{{ 'dewar-legal.terms-text' | translate }}</a>
                        </span>
                    </div>                    
                    <div class="copyright">                        
                        {{ copyright }} <span class="ms-3">CA License No. 0021741</span>
                    </div>     
                    <div class="disclaimer">
                        {{ disclaimer }}
                    </div>
                    
                } @else {
                <div class="nav">
                    <div class="nav-item">
                        <a class="nav-link" href="{{ 'intact-legal.legal' | translate }}" target="_blank">
                            {{ 'intact-legal.legal-text' | translate }}</a>
                    </div>
                    <div class="nav-item">
                        <a class="nav-link" href="{{ 'intact-legal.privacy' | translate }}" target="_blank">
                            {{ 'intact-legal.privacy-text' | translate }}</a>
                    </div>
                    <div class="nav-item">
                        <a class="nav-link" href="{{ 'intact-legal.producer-compensation' | translate }}" target="_blank">
                            {{ 'intact-legal.producer-compensation-text' | translate }}</a>
                    </div>
                    <div class="nav-item">
                        <a class="nav-link" href="{{ 'intact-legal.underwriting-companies' | translate }}" target="_blank">
                            {{ 'intact-legal.underwriting-companies-text' | translate }}</a>
                    </div>
                </div>
                <div class="copyright">
                    {{ copyright }}
                </div>
                <div class="disclaimer">
                    {{ disclaimer }}
                </div>
                }
            </div>
        </div>
    </div>
</div>

<!--
<div class="nav-item">
    <a class="nav-link" href="https://www.intactspecialty.com/en/legal/legal.page?" target="_blank">
        Legal</a>
</div>
<div class="nav-item">
    <a class="nav-link" href="https://www.intactspecialty.com/en/legal/privacy.page?" target="_blank">
        Privacy</a>
</div>
<div class="nav-item">
    <a class="nav-link" href="https://www.intactspecialty.com/en/legal/producer-compensation-disclosure.page?" target="_blank">
        Producer Compensation Disclosure</a>
</div>
<div class="nav-item">
    <a class="nav-link" href="https://www.intactspecialty.com/en/legal/underwriting-companies.page?" target="_blank">
        Underwriting Companies</a>
</div>
-->