import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-password-requirement',
    standalone: true,
    imports: [TranslateModule, ReactiveFormsModule],
    templateUrl: './password-requirement.component.html',
    styleUrl: './password-requirement.component.scss',
})
export class PasswordRequirementComponent {
    @Input({ required: true })
    parentForm!: FormGroup;

    constructor(private translate: TranslateService) {}

    get form() {
        return this.parentForm;
    }
}
