import { Directive, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[appShowError], textarea[appShowError], select[appShowError], radio[appShowError]',
    standalone: true,
})
export class ShowFieldErrorDirective {
    @Input() controlName?: string;

    constructor(@Optional() @Self() public ngControl: NgControl) {}
}
