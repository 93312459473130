import { AbstractControl, ValidatorFn } from '@angular/forms';

export class UsernameValidators {
    static hasIllegalChars(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const regex = new RegExp('[*&()^/\\\\/><%,+=]');
            const valid = regex.test(control.value);
            return !valid ? null : { hasIllegalChars: true };
        };
    }

    static containsSpace(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const regex = new RegExp(/\s/);
            const valid = regex.test(control.value);
            return !valid ? null : { containsSpace: true };
        };
    }
}
