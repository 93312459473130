import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentService, GlobalConstants } from '@app-core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-error',
    standalone: true,
    imports: [TranslateModule, RouterLink, RouterLinkActive],
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss',
})
export class ErrorComponent {
    errorIconPath: string | undefined;

    constructor(private environmentService: EnvironmentService) {
        this.errorIconPath = GlobalConstants.imageLocation + 'something-unexpected.svg';
    }
   
}

