import { Component, OnInit } from '@angular/core';
import { AlertService, EnvironmentService } from '@app-core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
    domain: string | undefined;
    brand: string = '';
    logoSrc: string | undefined;
    logoHref: string | undefined;
    logoAlt: string | undefined;
    languageToggle = 'Français';
    today = new Date();
    onLangChange: Observable<any> | undefined;
    langSubscription: Subscription = new Subscription();

    constructor(
        public translate: TranslateService,
        private cookieService: CookieService,
        private alertService: AlertService,
        private environmentService: EnvironmentService,
    ) {
        this.translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('en');
        this.domain = this.environmentService.domain;
        this.brand = this.environmentService.brand;
        this.logoSrc = this.environmentService.logo;
        this.logoHref = this.environmentService.originatingSite;
        this.logoAlt = this.environmentService.logoAlt;
    }

    ngOnInit() {
        const browserLang = this.translate.getBrowserLang();
        const userPreferredlang = this.cookieService.get('is-language');
        if (userPreferredlang) {
            this.translate.use(userPreferredlang);
            if (userPreferredlang === 'en') {
                this.languageToggle = 'Français';
            } else {
                this.languageToggle = 'English';
            }
        } else {
            this.translate.use(browserLang?.match(/en|fr/) ? browserLang : 'en');
        }

        this.updateLanguage();        
        this.langSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateLanguage();
        });
    }

    updateLanguage(): void {
        const lang = document.getElementsByTagName('html')[0];
        lang.setAttribute('lang', this.translate.currentLang);        
    }

    toggleUserLang() {
        this.alertService.clearAlerts();
        if (this.languageToggle === 'Français') {
            this.translate.use('fr');
            this.languageToggle = 'English';
            const expireDateName = new Date();
            expireDateName.setFullYear(expireDateName.getFullYear() + 10);
            this.cookieService.set('is-language', 'fr', expireDateName, '/', this.domain, false, 'Lax');
        } else {
            this.translate.use('en');
            this.languageToggle = 'Français';
            const expireDateName = new Date();
            expireDateName.setFullYear(expireDateName.getFullYear() + 10);
            this.cookieService.set('is-language', 'en', expireDateName, '/', this.domain, false, 'Lax');
        }
    }
}
