
@for (alert of alerts; track alert) {
<ngb-alert type="{{alert.type}}" [dismissible]=dismissible [animation]="true" (closed)="removeAlert(alert)" class="alert">
    <i class="icon {{iconClass(alert)}}" aria-hidden="true"></i>
    <div class="alert-content">
        @if (alert.heading) {      
            <div class="alert-heading">{{ alert.heading }}</div>        
        }
        <div class="alert-message" innerHTML="{{ alert.message }}"></div>
    </div>    
</ngb-alert>
}

