import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appCapsLock]',
    standalone: true,
})
export class CapsLockDirective {
    constructor() {}

    @Output('appCapsLock') capsLock = new EventEmitter<boolean>();

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
    }
    
    @HostListener('window:keyup', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
    }
}
