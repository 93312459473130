import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService, GlobalConstants } from '@app-core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-spinner',
    standalone: true,
    imports: [NgxSpinnerModule],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
    logoLocation: string;

    constructor(
        private spinnerService: NgxSpinnerService,
        private environmentService: EnvironmentService,
    ) {
        this.logoLocation = GlobalConstants.imageLocation + 'intact-favicon-32.png';
    }

    show() {
        this.spinnerService.show();
    }

    hide() {
        this.spinnerService.hide();
    }
}
