import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
    static hasIllegalChars(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const regex = new RegExp("[/\\\\/><']");
            const valid = regex.test(control.value);
            return !valid ? null : { hasIllegalChars: true };
        };
    }

    static hasIllegalStrings(names: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const value = control.value.toLowerCase();
            const matches = names.some((name) => value.includes(name.toLocaleLowerCase()));
            return matches ? { hasIllegalStrings: { value: control.value } } : null;
        };
    }

    static hasRepeatingCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const value = control.value;
            const regex = new RegExp("(.)\\1{3,}") 
            const matches = regex.test(value);
            return matches ? { hasRepeatingCharacters: { value: control.value } } : null;
        };
    }
}
