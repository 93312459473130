import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'form',
    standalone: true,
})

export class FormFocusDirective implements AfterViewInit {
    focusables = ['input', 'select', 'textarea', 'radio'];

    constructor(private element: ElementRef) {}

    ngAfterViewInit() {
        // Uncomment to focus on the first input field
        
        // const input = this.element.nativeElement.querySelector(this.focusables.join(','));
        // if (input) {
        //     input.focus();
        // }
    }

    @HostListener('submit')
    submit() {
        const input = this.element.nativeElement.querySelector(this.focusables.map((x) => `${x}.ng-invalid`).join(','));
        if (input) {
            input.focus();
        }
    }
}
