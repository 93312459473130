import { AbstractControl, ValidatorFn } from '@angular/forms';

export class AuthCodeValidators {
    static authCodeValid(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const regex = new RegExp('^[0-9]{6}$');
            const valid = regex.test(control.value);
            return valid ? null : { authCodeInvalid: true };
        };
    }
}
