import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[appShowPassword]',
    standalone: true,
})
export class ShowPasswordDirective implements OnInit, OnDestroy {
    @Input() addClass: string = '';
    private pwdShown = false;

    constructor(
        private element: ElementRef,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        const parent = this.element.nativeElement.parentNode;
        const span = document.createElement('span');
        span.innerHTML = `<i class="bi bi-eye-fill"></i>`;
        span.classList.add('toggle-password');
        if (this.addClass.length > 0) {
            span.classList.add(this.addClass);
        }
        span.addEventListener('click', () => {
            this.toggle(span);
        });
        parent.appendChild(span);

        // translate.get('common.reveal-password').subscribe((res: string) => {
        //     span.innerHTML = res;
        // });

        // this.langSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        //     this.updateLang(span);
        // });
    }

    toggle(span: HTMLElement) {
        this.pwdShown = !this.pwdShown;

        if (this.pwdShown) {
            this.element.nativeElement.setAttribute('type', 'text');
            span.innerHTML = `<i class="bi bi-eye-slash-fill"></i>`;
            // this.translate.get('common.hide-password').subscribe((res: string) => {});
        } else {
            this.element.nativeElement.setAttribute('type', 'password');
            span.innerHTML = `<i class="bi bi-eye-fill"></i>`;
            // this.translate.get('common.reveal-password').subscribe((res: string) => {});
        }
    }

    /* Commented not used, code demonstrates how to update the language of an element with ngx-translate in code */
    // updateLang(span: HTMLElement) {
    //     if (this.pwdShown) {
    //         this.translate.get('common.hide-password').subscribe((res: string) => {
    //             span.innerHTML = res;
    //         });
    //     } else {
    //         this.translate.get('common.reveal-password').subscribe((res: string) => {
    //             span.innerHTML = res;
    //         });
    //     }
    // }

    ngOnDestroy(): void {
        // this.langSubscription.unsubscribe();        
    }
}
