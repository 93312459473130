import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Alert, AlertService, AlertType, LoggingService } from '@app-core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-alert',
    standalone: true,
    imports: [NgbAlertModule, RouterLink, RouterLinkActive],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.scss',
})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() id: string = AlertService.defaultAlertID;
    @Input() animation = true;
    @Input() dismissible = false;
    @Input() type: string = AlertType.light;
    alerts: Alert[] = [];
    alertSubscription: Subscription = new Subscription();
    

    constructor(private alertService: AlertService, private router: Router) {}

    // Subscribe to new alert notifications from the AlertService BehaviorSubject
    // clear alerts when an empty alert is received
    ngOnInit(): void {
        this.alertSubscription = this.alertService.onAlert().subscribe((alert) => {
            // clear alerts when an empty alert is received or remove alerts with a specific ID when an alert with no message is received
            if (Object.keys(alert).length === 0) {
                this.alerts = [];
            } else if (alert.message === undefined) {
                this.alerts = this.alerts.filter((x) => x.id !== alert.id);
            } else {
                this.alerts.push(alert);
                window.scrollTo(0, 0);
                LoggingService.log('Alert: ', this.alerts);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 3000);
                }
            }
        });
    }

    // Based on the alert type set the CSS class for the alert box
    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        const classes = ['alert', 'alert-dismissable'];

        classes.push(alert.type);

        if (alert.fade) {
            classes.push('fade');
        }
        return classes.join(' ');
    }

    // Based on the alert type set the icon class for the alert box
    iconClass(alert: Alert): string | undefined {
        if (!alert) {
            return;
        }

        const classes = ['bi'];
        const iconTypeClass = {
            [AlertType.success]: 'bi-check-circle',
            [AlertType.danger]: 'bi-exclamation-circle',
            [AlertType.info]: 'bi-info-circle',
            [AlertType.warning]: 'bi-exclamation-triangle',
            [AlertType.primary]: '',
            [AlertType.secondary]: '',
            [AlertType.light]: '',
            [AlertType.dark]: '',
        };

        classes.push(iconTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }
        return classes.join(' ');
    }

    // Unsubscribe from the alert and route subscriptions
    ngOnDestroy(): void {
        this.alertSubscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        if (!this.alerts.includes(alert)) {
            return;
        } else {
            this.alerts = this.alerts.filter((x) => x !== alert);
        }
    }

}
