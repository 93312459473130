import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

type ErrorTypes =
    | 'required'
    | 'email'
    | 'pattern'
    | 'min'
    | 'max'
    | 'minlength'
    | 'maxlength'
    | 'containsSpace'
    | 'authCodeInvalid'
    | 'hasIllegalChars'
    | 'hasIllegalStrings'
    | 'hasRepeatingCharacters';

type FieldErrorMessage = {
    [key in ErrorTypes]: (...params: any) => string;
};

@Component({
    selector: 'app-field-error',
    standalone: true,
    imports: [CommonModule],
    template: `@if (shouldShowErrors()) {
        <div class="field-error" [ngClass]="classValue">
            <small class="form-text text-danger">{{ errorMessage }}</small>
        </div>
    }`,
    styleUrl: './field-error.component.scss',
})
export class FieldErrorComponent {
    @Input() control: AbstractControl | null = null;
    @Input() fieldName: string | undefined;
    @Input() classValue: string | undefined;
    private static required: string = '';
    private static email: string = '';
    private static pattern: string = '';
    private static min: string = '';
    private static max: string = '';
    private static minlength: string = '';
    private static maxlength: string = '';
    private static characters: string = '';
    private static containsSpace: string = '';
    private static authCodeInvalid: string = '';
    private static hasIllegalChars: string = '';
    private static hasIllegalStrings: string = '';
    private static hasRepeatingCharacters: string = '';

    constructor(private translate: TranslateService) {}

    get errorMessage(): string | null {
        const error = Object.entries(this.control?.errors! || {});
        let fieldName = '';

        this.initTranslations();

        if (this.fieldName !== undefined) {
            this.translate.get(this.fieldName).subscribe((res: string) => {
                fieldName = res;
            });
        } else {
            this.translate.get('inputs.field').subscribe((res: string) => {
                fieldName = res;
            });
        }
        return FieldErrorComponent.getErrorMessage(fieldName, error);
    }

    shouldShowErrors(): boolean {
        return this.control?.errors! && (this.control?.dirty! || this.control?.touched!);
    }

    initTranslations(): void {
        this.translate.get('field-errors.is-required').subscribe((res: string) => {
            FieldErrorComponent.required = res;
        });
        this.translate.get('field-errors.enter-valid-email').subscribe((res: string) => {
            FieldErrorComponent.email = res;
        });
        this.translate.get('field-errors.min-value').subscribe((res: string) => {
            FieldErrorComponent.min = res;
        });
        this.translate.get('field-errors.max-value').subscribe((res: string) => {
            FieldErrorComponent.max = res;
        });
        this.translate.get('field-errors.format').subscribe((res: string) => {
            FieldErrorComponent.pattern = res;
        });
        this.translate.get('field-errors.must-be-at-least').subscribe((res: string) => {
            FieldErrorComponent.minlength = res;
        });
        this.translate.get('field-errors.cannot-exceed').subscribe((res: string) => {
            FieldErrorComponent.maxlength = res;
        });
        this.translate.get('field-errors.characters').subscribe((res: string) => {
            FieldErrorComponent.characters = res;
        });
        this.translate.get('field-errors.cannot-contain-spaces').subscribe((res: string) => {
            FieldErrorComponent.containsSpace = res;
        });
        this.translate.get('field-errors.auth-code-invalid').subscribe((res: string) => {
            FieldErrorComponent.authCodeInvalid = res;
        });
        this.translate.get('field-errors.cannot-contain-special-chars').subscribe((res: string) => {
            FieldErrorComponent.hasIllegalChars = res;
        });
        this.translate.get('field-errors.cannot-contain-string').subscribe((res: string) => {
            FieldErrorComponent.hasIllegalStrings = res;
        });
        this.translate.get('field-errors.contains-repeating-chars').subscribe((res: string) => {
            FieldErrorComponent.hasRepeatingCharacters = res;
        });
    }

    // export class FieldErrorMessages {
    public static getErrorMessage(formControlName: string, errors: [string, any][]): string {
        switch (true) {
            case this.checkErrorType(errors, 'required'):
                return this.fieldErrorMessage['required'](formControlName);

            case this.checkErrorType(errors, 'email'):
                return this.fieldErrorMessage['email']();

            case this.checkErrorType(errors, 'pattern'):
                return this.fieldErrorMessage['pattern'](formControlName);

            case this.checkErrorType(errors, 'min'):
                const minValue = this.getErrorDetails(errors, 'min')?.min;
                return this.fieldErrorMessage['min'](formControlName, minValue);

            case this.checkErrorType(errors, 'max'):
                const maxValue = this.getErrorDetails(errors, 'max')?.max;
                return this.fieldErrorMessage['max'](formControlName, maxValue);

            case this.checkErrorType(errors, 'minlength'):
                const minRequirement = this.getErrorDetails(errors, 'minlength')?.requiredLength;
                return this.fieldErrorMessage['minlength'](formControlName, minRequirement);

            case this.checkErrorType(errors, 'maxlength'):
                const maxRequirement = this.getErrorDetails(errors, 'maxlength')?.requiredLength;
                return this.fieldErrorMessage['maxlength'](formControlName, maxRequirement);

            case this.checkErrorType(errors, 'containsSpace'):
                return this.fieldErrorMessage['containsSpace'](formControlName);

            case this.checkErrorType(errors, 'authCodeInvalid'):
                return this.fieldErrorMessage['authCodeInvalid'](formControlName);

            case this.checkErrorType(errors, 'hasIllegalChars'):
                return this.fieldErrorMessage['hasIllegalChars'](formControlName);

            case this.checkErrorType(errors, 'hasIllegalStrings'):
                return this.fieldErrorMessage['hasIllegalStrings'](formControlName);

            case this.checkErrorType(errors, 'hasRepeatingCharacters'):
                return this.fieldErrorMessage['hasRepeatingCharacters'](formControlName);

            default:
                return '';
        }
    }

    private static readonly fieldErrorMessage: FieldErrorMessage = {
        required: (formControlName) => `${formControlName} ${FieldErrorComponent.required}`,
        email: () => `${FieldErrorComponent.email}`,
        min: (formControlName, requirement) => `${formControlName} ${FieldErrorComponent.min} ${requirement}`,
        max: (formControlName, requirement) => `${formControlName} ${FieldErrorComponent.max} ${requirement}`,
        pattern: (formControlName) => `${formControlName} ${FieldErrorComponent.pattern}`,
        minlength: (formControlName, requirement) => `${formControlName} ${FieldErrorComponent.minlength} ${requirement} ${FieldErrorComponent.characters}`,
        maxlength: (formControlName, requirement) => `${formControlName} ${FieldErrorComponent.maxlength} ${requirement} ${FieldErrorComponent.characters}`,
        containsSpace: (formControlName) => `${formControlName} ${FieldErrorComponent.containsSpace}`,
        authCodeInvalid: (formControlName) => `${formControlName} ${FieldErrorComponent.authCodeInvalid}`,
        hasIllegalChars: (formControlName) => `${formControlName} ${FieldErrorComponent.hasIllegalChars}`,
        hasIllegalStrings: (formControlName) => `${formControlName} ${FieldErrorComponent.hasIllegalStrings}`,
        hasRepeatingCharacters: (formControlName) => `${formControlName} ${FieldErrorComponent.hasRepeatingCharacters}`,
    };

    private static checkErrorType(errors: [string, any][], key: ErrorTypes) {
        return errors.some(([errorKey, value]) => errorKey === key);
    }

    private static getErrorDetails(errors: [string, any][], key: ErrorTypes) {
        return errors.find(([k, v]) => k === key)?.[1];
    }
}
