import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '@app-core';
import { EnvironmentService } from '@app-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
    disclaimer: string = '';
    copyright: string = '';
    brand: string = '';

    constructor(private environmentService: EnvironmentService) {
        this.brand = this.environmentService.brand;
    }

    ngOnInit() {
        this.setCopyright();
    }

    private getYear() {
        return new Date().getFullYear();
    }

    private setCopyright() {
        switch (this.brand) {
            case 'tuitionrefundplan': {
                this.copyright = `© ${this.getYear()} A.W.G. Dewar, Inc. All rights reserved`;
                this.disclaimer = GlobalConstants.dewarLegalDisclaimer;
                break;
            }
            case 'nmu': {
                this.copyright = `© ${this.getYear()} National Marine Underwriters; A Member of Intact Insurance Group`;
                this.disclaimer = GlobalConstants.legalDisclaimer;
                break;            
            }
            default: {
                this.copyright = `© ${this.getYear()} Intact Insurance Group USA LLC`;
                this.disclaimer = GlobalConstants.legalDisclaimer;
                break;
            }
        }

        
    }
}
