<div class="password-validations">
    <!-- <div>
        <small class="text-muted">
            <i [class]="form.controls['password'].hasError('required') || form.controls['password'].hasError('minlength') ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            {{'captions.minimum-chars' | translate}}
        </small>
    </div> -->
    <div>
        <small class="text-muted">
            <i [class]="form.controls['password'].hasError('required') || form.controls['password'].hasError('noLowerCase') ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            {{'captions.contain-lower-case' | translate}}
        </small>
    </div>
    <div>
        <small class="text-muted">
            <i [class]="form.controls['password'].hasError('required') || form.controls['password'].hasError('noUpperCase') ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            {{'captions.contain-upper-case' | translate}}
        </small>
    </div>
    <div>
        <small class="text-muted">
            <i [class]="form.controls['password'].hasError('required') || form.controls['password'].hasError('noNumber') ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            {{'captions.contain-numbers' | translate}}
        </small>
    </div>
    <div>
        <small class="text-muted">            
            <i [class]="form.controls['password'].hasError('required') || form.controls['password'].hasError('noSpecialChars') ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            {{'captions.contain-special-chars' | translate}}
        </small>
    </div>

    <div>
        <small class="text-muted">
            <i [class]="form.controls['passwordScore'].hasError('required') || form.controls['passwordScore'].hasError('min') || form.controls['passwordScore'] === null ? 'bi bi-circle' : 'bi bi-check-circle-fill'"></i>
            Must meet minimum strength requirement
        </small>
    </div>

</div>