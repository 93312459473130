<div class="app-header">
    <div class="app-brand"><a href="{{logoHref}}"><img class="img-fluid logo" src="{{logoSrc}}" alt="{{logoAlt}}"></a></div>

    <div class="navbar-nav">
        @if (brand==='intactspecialty' || brand==='localhost') {
        <div class="nav-item">
            <i class="bi bi-telephone-fill"></i>
            <a class="nav-link" href="tel:8776247775">1-877-624-7775</a>
        </div>
        }
        @else if (brand==='nmu') {
        <div class="nav-item">
            <i class="bi bi-telephone-fill"></i>
            <a class="nav-link" href="tel:8776247775">1-877-624-7775</a>
        </div>
        }
        @else if (brand==='tuitionrefundplan') {
        <div class="nav-item">
            <i class="bi bi-telephone-fill"></i>
            <a class="nav-link" href="tel:6177741555">1-617-774-1555</a>
        </div>
        }

        @if (brand==='bondclick' || brand==='intactspecialty' || (brand==='localhost')) {
        <div class="nav-item">
            <a class="nav-link" (click)="toggleUserLang()">{{ languageToggle }}</a>
        </div>
        }
    </div>
</div>